import BreadCrumb from "Common/BreadCrumb"
import { createPlatformDraftRevisionByID, getPlatformById, getPlatformDraftById, publishPlatformDraftByID, updatePlatformByID, updatePlatformDraftByID } from "helpers/mb-api_helper"
import React, { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import ace from 'brace'
import { JsonEditor as Editor } from 'jsoneditor-react'


import 'jsoneditor-react/es/editor.min.css'
import 'brace/mode/json'
import 'brace/theme/github'
import { SaveAll, ShieldCheck } from "lucide-react"
import SnackBar from "Layout/SnackBar"


const Configuration = () => {
    const {id} = useParams()
    const navigate = useNavigate()
    const [config,setConfig] = useState({})
    const [platform,setPlatform] = useState({})
    const [isLoading,setIsLoading] = useState(true)
    const [message,setMessage] = useState("")
    const [success,setSuccess] = useState("")
    const [isPublished,setIsPublished] = useState("")
    const [readyToPublish,setReadyToPublish] = useState(false)
    useEffect(()=>{
        let getData = async()=>{
            try{
              let platformData = await getPlatformDraftById(id)
              setConfig(platformData.configuration || {})
              setPlatform(platformData)
              setIsLoading(false)
              setMessage("You are editing draft version of this platform")
            }catch(e){
              let platformData = await createPlatformDraftRevisionByID(id)
              setConfig(platformData.configuration || {})
              setPlatform(platformData)
              setIsLoading(false)
              setMessage("You are editing draft version of this platform")
            }
            setReadyToPublish(false)
        }
        getData()
    },[])
    useEffect(()=>{
        console.log("config",config)
    },[config])
    const savePlatformConfig = useCallback(async()=>{
      try{
        let result = await updatePlatformDraftByID(id,{"configuration":config})
        setSuccess("Draft copy saved successfully!")
        setMessage("Your draft copy is saved. You can publish the config now.")
        setReadyToPublish(true)
      }catch(e){
        setSuccess("Unable to save draft copy")
        setReadyToPublish(false)
        console.log(e)
      }
  },[config,id])


  const publishPlatform = useCallback(async()=>{
    try{
      let result = await publishPlatformDraftByID(id)
      setSuccess("Platform published successfully!")
      setReadyToPublish(true)
      setIsPublished(true)
    }catch(e){
      console.log(e)
    }
},[config,id])
    return(
        <>
            <BreadCrumb backRoute={`/platforms`} title={`Edit Config`} pageTitle="Platform Management" />
            {success && <SnackBar message={success} onClose={()=>setSuccess("")} />}
            {!isLoading && 
              <div className="card">
                  <div className="card-body flex flex-col gap-4">
                      <div className="flex justify-between items-center card px-4 py-2">
                          <div className="flex flex-col gap-1">
                              <h2 className="text-lg font-bold">Update Config for : {platform.name}</h2>
                              <p className="text-mb-red">{message}</p>
                          </div>
                      </div>
                      <div className={`w-full grow jeditor ${readyToPublish?'bg-gray-200 cursor-not-allowed pointer-events-none':''}`}>
                        <Editor
                          mode="tree"
                          navigationBar
                          statusBar
                          allowedModes={["tree","code","form"]}
                          htmlElementProps={[{style:{width:'100%'}}]}
                          className="w-full h-full"
                          value={config}
                          onChange={setConfig}
                          maxLines={100}
                          ace={ace}
                          theme="ace/theme/jsoneditor"
                        />
                      </div>
                      <div className="flex items-center justify-end mt-4 gap-4">
                        {readyToPublish && !isPublished && <button className="bg-mb-green text-white hover:bg-mb-green/20 hover:text-mb-green px-4 py-2 rounded-md flex items-center gap-2" onClick={publishPlatform}>
                          <ShieldCheck size={15} />
                          <span>Publish</span>
                        </button>}

                        {!readyToPublish && <button className="bg-mb-blue text-white hover:bg-mb-blue/10 hover:text-mb-blue px-4 py-2 rounded-md flex items-center gap-2" onClick={savePlatformConfig}>
                          <SaveAll size={15} />
                          <span>Save Draft</span>
                        </button>}
                      </div>
                  </div>
              </div>
            }
       </>
    
    )
}

export default Configuration