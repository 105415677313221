import BreadCrumb from "Common/BreadCrumb";
import { formatDate } from "coustumFuntions/capitalFirstLetter";
import { getJobBulkUploadData } from "helpers/mb-api_helper";
import { Plus } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const columnObject: any = {
  id: {
    header: "Id",
    accessorKey: "id",
    enableColumnFilter: false,
    enableSorting: true,
  },
  file_url: {
    header: "File Url",
    accessorKey: "file",
    enableColumnFilter: false,
    enableSorting: true,
  },
  content_type: {
    header: "Content Type",
    accessorKey: "content_type",
    enableColumnFilter: false,
    enableSorting: true,
  },
  status: {
    header: "Status",
    accessorKey: "status",
    enableColumnFilter: false,
    enableSorting: true,
  },
  success_file_url: {
    header: "Success File Url",
    accessorKey: "success_file_url",
    enableColumnFilter: false,
    enableSorting: true,
  },
  failed_file_url: {
    header: "Failed File Url",
    accessorKey: "failed_file_url",
    enableColumnFilter: false,
    enableSorting: true,
  },
  created_by: {
    header: "Created By",
    accessorKey: "created_by",
    enableColumnFilter: false,
    enableSorting: true,
  },
  updated_by: {
    header: "Updated By",
    accessorKey: "updated_by",
    enableColumnFilter: false,
    enableSorting: true,
  },
  created_at: {
    header: "Created At",
    accessorKey: "created_at",
    enableColumnFilter: false,
    enableSorting: true,
  },
  updated_at: {
    header: "Updated At",
    accessorKey: "updated_at",
    enableColumnFilter: false,
    enableSorting: true,
  },
};
const initialHeaders = [
  "id",
  "file_url",
  "content_type",
  "status",
  "success_file_url",
  "failed_file_url",
  // "created_by",
  // "updated_by",
  "created_at",
  "updated_at",
];
const BulkUpload = () => {
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    const getMappingData = async () => {
      try {
        const response: any = await getJobBulkUploadData();
        console.log("response: " + JSON.stringify(response));
        setRowData(response?.list);
      } catch (e) {
        console.log("error: " + e);
      }
    };
    getMappingData();
  }, []);
  return (
    <React.Fragment>
      <BreadCrumb title={"Bulk Import"} pageTitle="Asset Management" />
      <div className="text-xs grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="usersTable">
            <div className="card-body">
              <div className="flex items-center card justify-between p-4">
                  <h2 className="text-xl font-bold">Bulk Import Jobs</h2>
                <div className="flex items-center">
                  <Link to="/bulk-upload/add">
                    <button
                      type="button"
                      className="bg-mb-blue rounded-md text-white hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
                    >
                      <span className="align-middle"> Create </span>
                      <Plus className="inline-block size-4" />{" "}
                    </button>
                  </Link>
                </div>
              </div>

              <div className="overflow-x-auto scrollbar-hide border border-slate-200 rounded-md">
                <table className="w-full">
                  <thead className="ltr:text-left rtl:text-right ">
                    <tr>
                      {initialHeaders.map((header: any, i: number) => (
                        <th
                          key={i}
                          className="px-3.5 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
                        >
                          {columnObject[header].header}
                        </th>
                      ))}
                      {/* <th className="px-3.5 sticky right-0 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500 text-right bg-white">
                        Actions
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {rowData.map((asset: any, i: number) => {
                      return (
                        <tr
                          key={asset.id}
                          className="even:bg-slate-50 hover:bg-slate-50 even:hover:bg-slate-100 dark:even:bg-zink-600/50 dark:hover:bg-zink-600 dark:even:hover:bg-zink-600"
                        >
                          {initialHeaders.map((header: any, j: number) => {
                            return (
                              <td
                                key={asset.id + j}
                                className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
                              >
                                {header === "created_at" ||
                                header === "updated_at"
                                  ? formatDate(
                                      asset[columnObject[header].accessorKey]
                                    )
                                  : asset[columnObject[header].accessorKey]}
                              </td>
                            );
                          })}
                          {/* <td className="px-3.5 py-2.5 sticky right-0 border z-50 bg-white border-slate-200 dark:border-zink-500">
                            <div className="w-full flex gap-2 justify-end items-center">
                              <div>
                                <button className="w-auto py-0.5 font-bold text-mb-red">
                                  <Trash2Icon
                                    className="inline-block mr-2"
                                    size={15}
                                  />
                                </button>
                              </div>
                            </div>
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BulkUpload;
